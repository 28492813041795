import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Header } from '@data-products-and-ai/react-components';
import { Box, Typography, Button, Fade, Modal, Backdrop } from '@mui/material';
import './App.css';
import HomePage from './pages/Home/HomePage';
import Login from './pages/Login/Login';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import { useCallback, useState } from 'react';
import SupportComponent from './shared/components/SupportApp';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '90%',
  maxWidth: '80rem',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '8px',
  padding: '20px',
};

const paragraphStyle = {
  padding: '0',
};
function App() {
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const isInIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const ContactButton = () => {
    return (
      <Button
        color="secondary"
        onClick={handleModalOpen}
        key="contact-button"
        sx={{ textTransform: 'none' }}
      >
        <Typography sx={{ textAlign: 'justify', mb: 0 }}>Contact us</Typography>
      </Button>
    );
  };


  const interactionType = isInIframe()
    ? InteractionType.Popup
    : InteractionType.Redirect;

  const Main = () => {
    return (
      <MsalAuthenticationTemplate interactionType={interactionType}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          height="100%"
        >
          {!isInIframe() && <Header title='Parts Proactive Sales' hasSignOut  customComponents={[<ContactButton key="contact-button" />]}/>}
          <HomePage />

          <Modal
          open={openModal}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Box sx={modalStyle}>
              <Typography
                variant="h6"
                component="h2"
                sx={{ textAlign: 'justify', mb: 3 }}
              >
                Help and Improvements Request
              </Typography>
              <Box sx={paragraphStyle}>
                <SupportComponent />
              </Box>
            </Box>
          </Fade>
        </Modal>
        </Box>
      </MsalAuthenticationTemplate>
    );
  };

  const LoginToggle = () => {
    return (
      <div className='loginTemplate'>
        <AuthenticatedTemplate>
          <Main />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </div>
    );
  };
  

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/notfound" element={<NotFoundPage />} />
        <Route path="*" element={<LoginToggle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
