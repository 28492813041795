import {
  Divider,
  LoadingSpinner,
} from '@data-products-and-ai/react-components';
import SearchViewSelector from '../ui/SearchViewSelector';
import { useEffect, useState } from 'react';
import DataTable from '../ui/DataTable';

const PartsSales = () => {
  const [selectedView, setSelectedView] = useState('table');
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      field: 'id',
      headerName: 'Customer ID',
    },
    {
      field: 'name',
      headerName: 'Customer Name',
    },
    {
      field: 'country',
      headerName: 'Customer Country',
    },
    {
      field: 'machine',
      headerName: 'Machine/Unit ID',
    },
    {
      field: 'part',
      headerName: 'Part Number',
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
    },
    {
      field: 'price',
      headerName: 'Net Price',
    },    {
      field: 'currency',
      headerName: 'Currency',
    },    {
      field: 'value',
      headerName: 'Net Value',
    },
  ];

  useEffect(() => {
    console.log(selectedView);
    setLoading(false);
  }, []);
  
  function handleSearch(value: string): void {
    console.log(value);
    throw new Error('Function not implemented.');
  }

  return (
    <div>
      <div className="partsSales__container">
        <div className="partsSales__searchSection"></div>
        <SearchViewSelector dataSelectedView={setSelectedView} handleSearch={handleSearch}/>
        <Divider data-testid="divider"/>
        {loading ? (
          <div className="partsSales__loading">
            <LoadingSpinner
              color="#8a00e5"
              backgroundColor="#ADA9C2"
              text="Search results are loading"
            />{' '}
          </div>
        ) : (
          <DataTable columns={columns} data={[]} noResultsMessage='No results for your search terms.' />
        )}
      </div>
    </div>
  );
};
export default PartsSales;
