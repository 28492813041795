/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { jwtDecode } from "jwt-decode";
import { msalConfig } from "@/config/authConfig";

const useToken = () => {
  const { instance, accounts } = useMsal();

  const getToken = useCallback(async () => {
    try {
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: [`${msalConfig.auth.clientId}/.default`],
        account: accounts[0],
      });
      const idTokenDecoded = jwtDecode(tokenResponse.idToken);
      return {
        accessToken: tokenResponse.accessToken,
        idToken: tokenResponse.idToken,
        idTokenDecoded: idTokenDecoded,
        groupIds: (tokenResponse.idTokenClaims as any).groups || [],
        userInfo: {
          email: (idTokenDecoded as any).email,
          gid: (idTokenDecoded as any).SCGID,
        }
      };
    } catch (error) {
      console.error("Error fetching Token:", error);
      throw error;
    }
  }, [instance, accounts]);

  return getToken;
};

export default useToken;
