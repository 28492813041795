import { Button, Icon } from '@data-products-and-ai/react-components';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React from 'react';

interface DataTableProps {
  columns: {
    field: string;
    headerName: string;
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  resetFilter?: () => void;
  noResultsMessage?: string;
}

const color = '#8a00e5';

const DataTable: React.FC<DataTableProps> = ({
  columns,
  data,
  resetFilter,
  noResultsMessage,
}) => {
  const apiRef = useGridApiRef();

  const handleResetSorting = () => {
    apiRef.current?.setSortModel([]);
  };

  const handleResetFiltering = () => {
    apiRef.current?.setFilterModel({
      items: [],
    });
    if (resetFilter) resetFilter();
  };

  const handleDownloadCSV = () => {
    apiRef.current?.exportDataAsCsv({
      delimiter: ';',
      fileName: 'PPS_Customer_Ranking',
    });
  };

  const customSortComparator = (v1: string, v2: string) => {
    const order = ['Mid', 'High'];
    return order.indexOf(v1) - order.indexOf(v2);
  };

  const styledColumns = columns.map((column) => {
    return {
      ...column,
      headerClassName: 'dataTable__header',
      flex: 1,
      sortComparator:
        column.field === 'Engagement Score' ? customSortComparator : undefined,
    };
  });

  return (
    <div className="dataTable__container">
      <div className="dataTable__buttons">
        <Button
          allCaps={false}
          size="small"
          type="primaryOutline"
          icon={<Icon icon="IconSort" iconSize={0.7} color={color}></Icon>}
          onClick={handleResetSorting}
          data-testid="reset-sorting-button"
        >
          Reset Sorting
        </Button>
        <Button
          allCaps={false}
          size="small"
          type="primaryOutline"
          icon={<Icon icon="IconFilter" iconSize={0.7} color={color}></Icon>}
          onClick={handleResetFiltering}
          data-testid="reset-filtering-button"
        >
          Reset Filtering
        </Button>
        <Button
          allCaps={false}
          size="small"
          type="primaryOutline"
          icon={<Icon icon="IconDownload" iconSize={0.7} color={color}></Icon>}
          onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
      </div>
      <DataGrid
        columns={styledColumns}
        rows={data}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? '--even' : '--odd'
        }
        apiRef={apiRef}
        slots={{
          noRowsOverlay: () => (
            <b className="dataTable--noResults">
              {noResultsMessage ?? 'No results were found.'}
            </b>
          ),
        }}
        data-testid="data-grid-table"
      />
    </div>
  );
};

export default DataTable;
