import DataTable from '../ui/DataTable';

const KeyMetrics = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'Customer ID',
    },
    {
      field: 'name',
      headerName: 'Customer Name',
    },
    {
      field: 'country',
      headerName: 'Customer Country',
    },
    {
      field: 'revenue',
      headerName: 'Incremental Revenue',
    },
    {
      field: 'accuracy',
      headerName: 'Accuracy',
    },
    {
      field: 'productivity',
      headerName: 'Productivity',
    },
  ];

  return (
    <div className="keyMetrics__container">
      <div className="keyMetrics__header__container">
        <article className="keyMetrics__header">
          <h1 className="keyMetrics__header__title">Live Metrics</h1>
          <div className="keyMetrics__header__metrics">
            <div className="keyMetrics__displayer">
              <div>
                <h2 className="keyMetrics__displayer__title">
                  Incremental Revenue
                </h2>
                <span className="keyMetrics__displayer__subtitle">
                  January 2023
                </span>
              </div>
              <h3 className="keyMetrics__displayer--value">50,000 $</h3>
              <div className="keyMetrics__displayer__footer">
                <span className="keyMetrics__displayer__subtitle">
                  Updated 23.01.2023
                </span>
              </div>
            </div>
            <div className="keyMetrics__displayer">
              <div>
                <h2 className="keyMetrics__displayer__title">Accuracy</h2>
                <span className="keyMetrics__displayer__subtitle">
                  January 2023
                </span>
              </div>
              <h3 className="keyMetrics__displayer--value">87%</h3>
              <div className="keyMetrics__displayer__footer">
                <span className="keyMetrics__displayer__subtitle">
                  Updated 23.01.2023
                </span>
              </div>
            </div>
            <div className="keyMetrics__displayer">
              <div>
                <h2 className="keyMetrics__displayer__title">Productivity</h2>
                <span className="keyMetrics__displayer__subtitle">
                  January 2023
                </span>
              </div>
              <h3 className="keyMetrics__displayer--value">18</h3>
              <div className="keyMetrics__displayer__footer">
                <span className="keyMetrics__displayer__subtitle">
                  Updated 23.01.2023
                </span>
              </div>
            </div>
          </div>
        </article>
        <article className="keyMetrics__statistics">
          <h4 className="keyMetrics__statistics__title">
            <b>Usage Statistics</b> Strategic Pricing Tool
          </h4>
          <ul className="keyMetrics__statistics__list">
            <li className="keyMetrics__statistics__item">
              <span className="keyMetrics__statistics__item--prop">
                Log ins
              </span>
              <span className="keyMetrics__statistics__item--value">2500</span>
            </li>
            <li className="keyMetrics__statistics__item">
              <span className="keyMetrics__statistics__item--prop">
                Active Users
              </span>
              <span className="keyMetrics__statistics__item--value">56</span>
            </li>
            <li className="keyMetrics__statistics__item">
              <span className="keyMetrics__statistics__item--prop">
                Average Time spent in minutes
              </span>
              <span className="keyMetrics__statistics__item--value">30</span>
            </li>
          </ul>
        </article>
      </div>

      <DataTable columns={columns} data={[]} />
      <div className="keyMetrics__footer">
      </div>
    </div>
  );
};

export default KeyMetrics;
