import {useEffect, useState } from 'react';
import SearchViewSelector from '../ui/SearchViewSelector';
import DataTable from '../ui/DataTable';
import {
  Divider,
  LoadingSpinner,
} from '@data-products-and-ai/react-components';
import moment from 'moment';
import { axiosInstance } from '@/utils/axiosUtil';
import useToken from '@/utils/useToken';

const CustomerRanking = () => {
  const [selectedView, setSelectedView] = useState('table');
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [token, setToken] = useState<any>(null);
  const getToken = useToken();

  interface CustomerData {
    id: string;
    'Customer ID': string;
    'Customer Name': string;
    'Customer Country': string;
    'Last Active': string;
    'Engagement Score': string;
    Machine: string;
    'Expected Time Frame': string;
    'Prediction Driver': string;
  }

  const [tableData, setTableData] = useState<CustomerData[]>([]);
  const columns = [
    {
      field: 'Customer ID',
      headerName: 'Customer ID',
    },
    {
      field: 'Customer Name',
      headerName: 'Customer Name',
    },
    {
      field: 'Customer Country',
      headerName: 'Customer Country',
    },
    {
      field: 'Last Active',
      headerName: 'Last Active',
    },
    {
      field: 'Engagement Score',
      headerName: 'Engagement Score',
    },
    {
      field: 'Machine',
      headerName: 'Machine/Unit ID',
    },
    {
      field: 'Expected Time Frame',
      headerName: 'Expected Time Frame',
    },
    {
      field: 'Prediction Driver',
      headerName: 'Prediction Driver',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = await getToken();
      setToken(token);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (token) {
      console.log(selectedView);
      setLoading(false);
      getData();
    }
  }, [token]);

  const getData = () => {
    axiosInstance
      .get('/ranking', {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setTableData(
          response.data.map((item: CustomerData) => {
            return {
              ...item,
              'Last Active': moment(item['Last Active']).format('DD/MM/YYYY'),
              'Expected Time Frame': moment(item['Expected Time Frame']).format(
                'MM/YYYY',
              ),
              id: item['Customer ID'],
            };
          }),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (value?: string) => {
    if (!value) {
      getData();
    } else {
      const filteredData = tableData.filter(
        (item: CustomerData) => item['Customer ID'] == value,
      );
      setTableData(filteredData);
    }
  };

  return (
    <div className="ranking__container">
      <div className="ranking__searchSection"></div>
      <SearchViewSelector
        dataSelectedView={setSelectedView}
        handleSearch={handleSearch}
      />
      <Divider />
      {loading ? (
        <div className="ranking__loading">
          <LoadingSpinner
            color="#8a00e5"
            backgroundColor="#ADA9C2"
            text="Search results are loading"
          />{' '}
        </div>
      ) : (
        <DataTable columns={columns} data={tableData} />
      )}
    </div>
  );
};

export default CustomerRanking;
