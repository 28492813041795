import { Tabs } from '@data-products-and-ai/react-components';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import NotFoundPage from '../NotFound/NotFoundPage';
import CustomerRanking from '@/shared/components/CustomerRanking';
import KeyMetrics from '@/shared/components/KeyMetrics';
import PartsSales from '@/shared/components/PartsSales';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = ['key-metrics', 'customer-ranking', 'parts-sales'];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    const path = location.pathname.slice(1);
    if (tabs.includes(path)) {
      setSelectedTab(path);
    } else {
      setSelectedTab('key-metrics');
    }
  }, []);

  return (
    <div className="homePage">
      <div className="homePage__grid">
        <div className="homePage__container">
          <div className="homePage__tabs">
            <Tabs
              fontSize={18}
              centered={false}
              selectedHasBold={true}
              hasBorder={true}
              selectedKey={selectedTab}
              onChange={(elem) => {
                navigate(elem);
              }}
              data={[
                {
                  key: 'key-metrics',
                  label: 'Key Metrics',
                },
                {
                  key: 'customer-ranking',
                  label: 'Customer Ranking',
                },
                {
                  key: 'parts-sales',
                  label: 'Parts Sales',
                },
              ]}
            />
          </div>
          <Routes>
            <Route path="/key-metrics" element={<KeyMetrics />} />
            <Route path="/customer-ranking" element={<CustomerRanking />} />
            <Route path="/parts-sales" element={<PartsSales/>} />
            <Route path="/" element={<Navigate to="/key-metrics" />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
