import {
  ButtonIcon,
  Icon,
  Input,
  Tooltip,
} from '@data-products-and-ai/react-components';
import React, { useState } from 'react';

interface SearchViewSelectorProps {
  dataSelectedView: (event: string) => void;
  handleSearch: (value: string) => void;
}

const SearchViewSelector: React.FC<SearchViewSelectorProps> = ({
  dataSelectedView,
  handleSearch,
}) => {
  const [selectedView, setSelectedView] = useState('table');
  const [inputValue, setInputValue] = useState('');

  const handleRadioChange = (event: { target: { value: string } }) => {
    setSelectedView(event.target.value);
    console.log(event.target.value);
    dataSelectedView(event.target.value);
  };

  return (
    <div className="searchView-container" data-testid="search-view-selector">
      <div className="searchView-searchBar">
        <div className="searchView-searchBar-input">
          <Input
            id="customerId"
            textBefore="Customer ID"
            width={'100%'}
            isNumberInput={true}
            onChange={(event) => setInputValue(event.target.value)}
          />
        </div>
        <ButtonIcon icon="IconSearch" onClick={()=> handleSearch(inputValue)}/>
      </div>
      <div className="searchView-buttons">
        <Tooltip title="Table View">
          <label>
            <input
              type="radio"
              name="radios"
              value="table"
              checked={selectedView === 'table'}
              onChange={handleRadioChange}
            />
            <div>
              <Icon icon="IconBrowseAll" iconSize={1.22} />
            </div>
          </label>
        </Tooltip>
        <Tooltip title="Graph View">
          <label>
            <input
              type="radio"
              name="radios"
              value="chart"
              checked={selectedView === 'chart'}
              onChange={handleRadioChange}
            />
            <div>
              <Icon icon="IconLineChart" iconSize={1.22} />
            </div>
          </label>
        </Tooltip>
      </div>
    </div>
  );
};

export default SearchViewSelector;
